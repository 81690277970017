import urls from "../../api/urls";
import useAPI from "./useAPI";
import { useState } from "react";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";
import { outletTypes } from "../../utils/vars";

function useOutlets(props) {
  const [totalCount, setTotalCount] = useState(0);
  const { loading, post, get, put } = useAPI();
  const [outlets, setOutlets] = useState([]);
  const [iCarts, setICarts] = useState([]);

  const getOutlets = async (pageNumber = 1, limit = 10, type) => {
    const { data, error } = await get(
      addParamsToUrl(urls.outlets.baseUrl, { pageNumber, limit, type })
    );
    if (error) return;
    setTotalCount(data.pagination.total);
    setOutlets(data.operationOutlets);
  };
  const getICarts = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.icarts.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setTotalCount(data.pagination.total);
    setICarts(data.iCarts.filter((_) => !_.purchased));
  };

  const getOperationalOutlets = async (pageNumber = 1, limit = 10) => {
    const offices =
      (await getOutlets(pageNumber, limit, outletTypes.office)) || [];
    const prepRoom =
      (await getOutlets(pageNumber, limit, outletTypes.prepRoom)) || [];
    const store =
      (await getOutlets(pageNumber, limit, outletTypes.store)) || [];

    setOutlets([...offices, ...prepRoom, ...store]);
  };

  const createOutlet = async (fdata, cb) => {
    const { error, message } = await post(urls.outlets.baseUrl, fdata);
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const updateOutlet = async (id, fdata, cb) => {
    const { error, message } = await put(
      urls.outlets.baseUrl + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  return {
    loading,
    iCarts,
    outlets,
    getICarts,
    totalCount,
    getOutlets,
    createOutlet,
    updateOutlet,
    getOperationalOutlets,
  };
}
export default useOutlets;
