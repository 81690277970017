import { useContext, useState } from "react";
import AppContext from "../../contexts/AppContext";
import { Form, FormInput, Submit } from "../form";
import Modal from "../Modal";
import { locationSchema } from "../../utils/validators/management";
import Button from "../Button";
import FormattedInput from "../FormattedInput";

function LocationProfile({
  closeModal,
  loading,
  selectedLocation,
  onSave,
  isOpened,
}) {
  const [type, setType] = useState(null);
  const { cities } = useContext(AppContext);
  return (
    <Modal isVisible={isOpened}>
      <h2 className="fs-24 raleway f-700 text-primary">
        {selectedLocation
          ? `Update ${selectedLocation?.label}`
          : "Register New Location"}
      </h2>
      <br />
      <Form
        onSubmit={onSave}
        validationSchema={locationSchema}
        initialValues={{
          label: selectedLocation?.label || "",
          city: selectedLocation?.city?._id || "",
          lat: selectedLocation?.lat || "",
          lng: selectedLocation?.lng || "",
          type: selectedLocation?.type || "",
          fee: selectedLocation?.fee || "",
        }}
      >
        <div className="inputs">
          <FormInput name="label" placeholder="Label" />
          <FormInput
            select
            options={cities.map((_) => ({ label: _.name, value: _._id }))}
            name="city"
            placeholder="City"
          />
        </div>
        <br />
        <div className="inputs">
          <FormInput type="number" name="lat" placeholder="Latitude" />
          <FormInput type="number" name="lng" placeholder="Longitude" />
        </div>
        <br />
        <FormInput
          listener={setType}
          options={[
            {
              label: "iCart Deployment",
              value: "kiosk",
            },
            {
              label: "Operations Outlet",
              value: "outlet",
            },
          ]}
          name="type"
          select
          placeholder="Type"
        />
        <br />
        {type === "kiosk" ? (
          <>
            <FormInput
              type="number"
              name="fee"
              placeholder="Subscription Fee"
            />
            <br />
          </>
        ) : null}
        <div className="d-flex gap-10">
          <Button onClick={closeModal} width={100} title="Cancel" outline />
          <Submit loading={loading} title="Save" />
        </div>
      </Form>
    </Modal>
  );
}

export default LocationProfile;
