import { useState } from "react";
import useAPI from "./useAPI";
import urls from "../../api/urls";
import { addParamsToUrl } from "../../utils/helpers";
import { toast } from "react-toastify";
import { objectToFormData } from "../../utils/array";
import store from "../../utils/store";

const useInventory = () => {
  // eslint-disable-next-line
  const [productHistory, setProductHistory] = useState([]);
  const [materialPrices, setMaterialPrices] = useState([]);
  const [pagination, setPagination] = useState({});
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [products, setProducts] = useState([]);
  const [data, setData] = useState(null);

  const { get, post, put, patch, loading, errorResponse } = useAPI();

  const getProducts = async (outlet, pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.inventory.products.all, { outlet, pageNumber, limit })
    );
    if (error) return;
    setProducts(data.inventoryProducts);
    setPagination(data.pagination);
  };

  const getCategories = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.inventory.categories.all, { pageNumber, limit })
    );
    if (error) return;
    setCategories(data.materialCategories);
    setPagination(data.pagination);
  };

  const getProductHistory = async (id, pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.inventory.products.history.replace("[id]", id), {
        pageNumber,
        limit,
      })
    );
    if (error) return;
    setProductHistory(data.materialCategories);
  };

  const addCategory = async (fdata, cb) => {
    const { data, error } = await post(urls.inventory.categories.all, fdata);
    if (error) return;
    toast.info(data.message);
    getCategories(1, 100);
    if (typeof cb === "function") cb();
  };

  const updateCategory = async (id, fdata, cb) => {
    const { data, error } = await put(
      urls.inventory.categories.all + id,
      fdata
    );
    if (error) return;
    toast.info(data.message);
    getCategories(1, 100);
    if (typeof cb === "function") cb();
  };

  const addMaterial = async (fdata, img, cb) => {
    fdata = objectToFormData(fdata);
    fdata.append("image", img);
    const { data, error } = await post(urls.inventory.materials.all, fdata);
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };

  const updateMaterial = async (id, fdata, img, cb) => {
    fdata = objectToFormData(fdata);
    if (img) {
      fdata.append("image", img);
    }
    const { data, error } = await put(
      urls.inventory.materials.all + `/${id}`,
      fdata
    );
    if (error) return;
    toast.info(data.message);
    if (typeof cb === "function") cb();
  };

  const getMaterials = async (pageNumber, limit) => {
    const { data, error } = await get(
      addParamsToUrl(urls.inventory.materials.all, { pageNumber, limit })
    );
    if (error) return;
    setMaterials(data.materials);
    setPagination(data.pagination);
  };

  const getMaterialPrices = async (material, region) => {
    // return { city: region, _id: material, cost: 0 };
    const key = `saved_material_${material}_${region}`;
    const saved = store.getItem(key);
    if (saved) return saved;
    const { data, error } = await get(
      addParamsToUrl(urls.inventory.materials.cost.replace(":id", material), {
        region,
      })
    );
    if (error) return;
    setMaterialPrices(data.costs);
    store.setItem(key, data.costs[0]);
    return data.costs[0];
  };

  const updateMaterialPrice = async (material, fdata, cb) => {
    const { error, message } = await post(
      urls.inventory.materials.cost.replace(":id", material),
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const searchMaterial = async (q) => {
    if (!q) return setMaterials([]);
    const { data, error } = await get(urls.inventory.materials.search + q);
    if (error) return;
    setMaterials(data);
  };

  const sendMaterialsRequest = async (fdata, origin, cb) => {
    const { error, message } = await post(
      addParamsToUrl(urls.inventory.requestSupply, { origin }),
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const acceptSupply = async (fdata, id, cb) => {
    const { error, message } = await patch(
      urls.inventory.acceptSupply.replace(":id", id),
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const confirmDelivery = async (fdata, id, cb) => {
    const { error, message } = await patch(
      urls.inventory.confirmDelivery.replace(":id", id),
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const sendDelivery = async (fdata, id, cb) => {
    alert("send delivery");
    const { error, message } = await patch(
      urls.inventory.sendDelivery.replace(":id", id),
      fdata
    );
    if (error) return;
    toast.info(message);
    if (typeof cb === "function") cb();
  };

  const getMaterialRequests = async (
    origin,
    pageNumber = 1,
    limit = 10,
    source
  ) => {
    const { data, error } = await get(
      addParamsToUrl(urls.inventory.requestSupply, {
        pageNumber,
        limit,
        origin,
        source,
      })
    );
    if (error) return;
    setPagination(data.pagination);
    setProducts(data.supplyRequests);
  };
  const getMaterialRequestDetails = async (id) => {
    const { data, error } = await get(urls.inventory.requestSupply + `/${id}`);
    if (error) return;
    setData(data.supplyRequest);
  };

  const getMaterialDetals = async (id) => {
    const { data, error } = await get(urls.inventory.materials.all + `/${id}`);
    if (error) return;
    setData(data.material);
  };

  return {
    data,
    loading,
    products,
    categories,
    materials,
    pagination,
    getProducts,
    addMaterial,
    setMaterials,
    addCategory,
    acceptSupply,
    getMaterials,
    getCategories,
    updateMaterial,
    errorResponse,
    searchMaterial,
    updateCategory,
    materialPrices,
    sendDelivery,
    confirmDelivery,
    getProductHistory,
    getMaterialPrices,
    getMaterialDetals,
    updateMaterialPrice,
    getMaterialRequests,
    sendMaterialsRequest,
    getMaterialRequestDetails,
  };
};

export default useInventory;
