import { addParamsToUrl } from "../../utils/helpers";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";
import store from "../../utils/store";
import { toast } from "react-toastify";

function useSubscriptions(props) {
  const [totalCount, setTotalCount] = useState(0);
  const [susbcriptions, setSubscriptions] = useState([]);
  const { loading, get, patch } = useAPI();

  const getSubscriptions = async (pageNumber = 1, limit = 10) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.subscriptions.baseUrl, { pageNumber, limit })
    );
    if (error) return;
    setSubscriptions(data.subscriptions);
    setTotalCount(data.pagination.total);
  };

  const asignOutletToSub = async (subId, outlet, cb) => {
    const { data, error, message } = await patch(
      addParamsToUrl(
        urls.business.subscriptions.asingOutlet.replace(":subId", subId),
        { outlet }
      )
    );
    if (error) return;
    toast.info(message || data.message);
    if (typeof cb === "function") cb();
  };
  const asignKioskToSub = async (subId, iCartId, cb) => {
    const { data, error, message } = await patch(
      addParamsToUrl(
        urls.business.subscriptions.asingKiosk.replace(":subId", subId),
        { iCartId }
      )
    );
    if (error) return;
    toast.info(message || data.message);
    if (typeof cb === "function") cb();
  };

  const validateSubscription = async (purchaseCode, cb) => {
    const { data, error } = await get(
      addParamsToUrl(urls.business.subscriptions.validate, { purchaseCode })
    );
    if (error) return;
    store.setItem("outlet", data.outlet);
    if (typeof cb === "function") cb();
  };

  return {
    validateSubscription,
    getSubscriptions,
    asignKioskToSub,
    asignOutletToSub,
    susbcriptions,
    totalCount,
    loading,
  };
}
export default useSubscriptions;
